import {useMemo} from 'react';
import {useRouter} from 'next/router';

const ABTest = ({Control, variations, id = 'abtest', useUseMemo = true}) => {
    const {asPath = ''} = useRouter() || {};

    const renderVariations = () => {
        return (
            <div id={`abtest__${id}`}>
                <div className="test__control">
                    <Control />
                </div>
                {Array.isArray(variations) &&
                    variations.map((Variant, index) => {
                        const variantNumber = index + 1;
                        return (
                            <div
                                key={index}
                                className={`variant test__variant-${variantNumber}`}>
                                <Variant />
                            </div>
                        );
                    })}
                <style jsx>
                    {`
                        .test__control {
                            display: block;
                        }
                        .variant {
                            display: none;
                        }
                    `}
                </style>
            </div>
        );
    };
    return <>{useUseMemo ? useMemo(renderVariations, [asPath]) : renderVariations()}</>;
};
export default ABTest;
