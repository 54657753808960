export const customMapLinks = {
    ADC: {
        title: 'AdCare Treatment Hospital',
        url: '/treatment-centers/adcare-hospital',
    },
    ARI: {
        title: 'AdCare Rhode Island',
        url: '/treatment-centers/adcare-rhode-island',
    },
    AHW: {
        title: 'AdCare Treatment Hospital',
        url: '/treatment-centers/adcare-hospital',
    },
    DSH: {
        title: 'Desert Hope Treatment Center',
        url: '/treatment-centers/desert-hope',
    },
    GRN: {
        title: 'Greenhouse Treatment Center',
        url: '/treatment-centers/greenhouse',
    },
    LAG: {
        title: 'Laguna Treatment Center',
        url: '/treatment-centers/laguna-treatment-center',
    },
    REF: {
        title: 'Recovery First Treatment Center',
        url: '/treatment-centers/recovery-first-hollywood',
    },
    ROA: {
        title: 'River Oaks Treatment Center',
        url: '/treatment-centers/river-oaks',
    },
    XFO: {
        title: 'Oxford Treatment Center',
        url: '/treatment-centers/oxford',
    },
};
