import {useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {getSegmentNumber, getSlug, getTags, isSpanishPage} from '../../../lib/utils';
import {navItems} from './navItems';
import AppContext from '../../AppContext';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import HiddenOnMobile from 'aac-components/components/HiddenOnMobile';
import NextStepsNavMobile from './NextStepsNavMobile';
import NextStepsNavDesktop from './NextStepsNavDesktop';

/* ABTasty Campaign Imports */
import ABTest from '../../ABTest';
import Variation1 from '../../AAC2-3833/Variation1';

const NextStepsNav = () => {
    const {asPath} = useRouter();
    const slug = getSlug(asPath);

    const spanishPage = isSpanishPage(slug);

    const {isMobile, pageProps} = useContext(AppContext);

    const [items, setItems] = useState(navItems?.default);
    const [isClient, setIsClient] = useState(false);

    const {
        sessionStorageState: {geolocation: {facility: geolocationSiteKey = ''} = {}} = {},
    } = useContext(AppContext);

    useEffect(() => {
        const tags = getTags();
        const segmentNumber = getSegmentNumber();
        const segmentName = pageProps?.data?.acf?.content_segment;

        const isOutpatient =
            asPath?.includes('outpatient') || segmentName === 'info-drug-nodetox';

        if (tags?.includes('veterans')) {
            setItems(navItems?.veterans);
            return;
        } else if (spanishPage) {
            setItems(navItems?.spanish);
            return;
        } else if (isOutpatient) {
            setItems(navItems?.outpatient);
            return;
        } else if (segmentNumber <= 2) {
            setItems(navItems?.lowSegment);
            return;
        } else {
            setItems(navItems?.default);
        }
    }, [asPath]);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) return null;

    return (
        <>
            {geolocationSiteKey ? (
                <ABTest
                    id="AAC2-3833"
                    Control={() => (
                        <>
                            {isMobile ? (
                                <HiddenOnDesktop>
                                    <NextStepsNavMobile items={items} />
                                </HiddenOnDesktop>
                            ) : (
                                <HiddenOnMobile>
                                    <NextStepsNavDesktop items={items} />
                                </HiddenOnMobile>
                            )}
                        </>
                    )}
                    variations={[() => <Variation1 isMobile={isMobile} />]}
                />
            ) : (
                <>
                    {isMobile ? (
                        <HiddenOnDesktop>
                            <NextStepsNavMobile items={items} />
                        </HiddenOnDesktop>
                    ) : (
                        <HiddenOnMobile>
                            <NextStepsNavDesktop items={items} />
                        </HiddenOnMobile>
                    )}
                </>
            )}
        </>
    );
};
export default NextStepsNav;
