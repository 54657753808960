import OutsideClick from 'aac-components/components/OutsideClick';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {isMappableArray, zIndex} from 'aac-components/utils/helpers';
import {useEffect, useState} from 'react';
import styled from 'styled-components';

const Filter = ({
    onStateChange,
    onInsuranceChange,
    selectedState = null,
    selectedInsurance = null,
    label,
    showOutpatient = true,
    showInsuranceFilter = false,
    options = states,
}) => {
    const [stateSelection, setStateSelection] = useState(null);
    const [insuranceSelection, setInsuranceSelection] = useState(null);
    const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
    const [isInsuranceDropdownOpen, setIsInsuranceDropdownOpen] = useState(false);
    const [disableInsuranceFilter, setDisableInsuranceFilter] = useState(false);

    useEffect(() => {
        onStateChange && onStateChange(stateSelection);
    }, [stateSelection]);

    useEffect(() => {
        onInsuranceChange && onInsuranceChange(insuranceSelection);
    }, [insuranceSelection]);

    useEffect(() => {
        selectedState === null && setStateSelection(null);
    }, [selectedState]);

    useEffect(() => {
        selectedInsurance === null && setStateSelection(null);
    }, [selectedInsurance]);

    useEffect(() => {
        if (selectedState === 'Outpatient & Sober Living') {
            setDisableInsuranceFilter(true);
        } else {
            setDisableInsuranceFilter(false);
        }
    }, [selectedState, disableInsuranceFilter]);

    const hasFilteredState = selectedState !== null;

    const hasFilteredInsurance = selectedInsurance !== null;

    return (
        <>
            {/* state dropdown filter */}
            <div className="filter">
                <div className="filter__label">
                    {label}
                    {(hasFilteredState || hasFilteredInsurance) && (
                        <span
                            onClick={() => {
                                setStateSelection(null);
                                setInsuranceSelection(null);
                            }}
                            className="filter__clear">
                            Clear
                        </span>
                    )}
                </div>
                <OutsideClick onClick={() => setIsStateDropdownOpen(false)}>
                    <div
                        className="filter__input"
                        onClick={() =>
                            setIsStateDropdownOpen((prevInputState) => !prevInputState)
                        }>
                        {stateSelection || 'Select State'}
                        <IconChevron
                            rotate={isStateDropdownOpen ? '180' : '0'}
                            fill="var(--gray-400)"
                        />
                    </div>
                    {isStateDropdownOpen && (
                        <div className="filter__options">
                            {isMappableArray(options) &&
                                options.map((state) => {
                                    if (
                                        !showOutpatient &&
                                        state === 'Outpatient & Sober Living'
                                    )
                                        return null;
                                    return (
                                        <div
                                            key={state}
                                            className="filter__option"
                                            onClick={() => {
                                                setStateSelection(state);
                                                setIsStateDropdownOpen(false);
                                            }}
                                            dangerouslySetInnerHTML={{__html: state}}
                                        />
                                    );
                                })}
                        </div>
                    )}
                </OutsideClick>
            </div>
            {/* insurance dropdown filter */}
            {showInsuranceFilter && (
                <div
                    className="filter"
                    style={
                        disableInsuranceFilter
                            ? {opacity: 0.3, cursor: 'not-allowed', pointerEvents: 'none'}
                            : {}
                    }>
                    <OutsideClick onClick={() => setIsInsuranceDropdownOpen(false)}>
                        <div
                            className="filter__input"
                            onClick={() =>
                                setIsInsuranceDropdownOpen(
                                    (prevInputState) => !prevInputState,
                                )
                            }>
                            {insuranceSelection || 'Select Insurance'}
                            <IconChevron
                                rotate={isInsuranceDropdownOpen ? '180' : '0'}
                                fill="var(--gray-400)"
                            />
                        </div>
                        {isInsuranceDropdownOpen && (
                            <div className="filter__options">
                                {isMappableArray(insurances) &&
                                    insurances.map((insurance) => {
                                        return (
                                            <div
                                                key={insurance}
                                                className="filter__option"
                                                onClick={() => {
                                                    setInsuranceSelection(insurance);
                                                    setIsInsuranceDropdownOpen(false);
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: insurance,
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        )}
                    </OutsideClick>
                </div>
            )}
            <style jsx>
                {`
                    .filter {
                        position: relative;
                    }
                    .filter:nth-of-type(2) {
                        margin-top: 15px;
                    }
                    .filter__label {
                        margin-bottom: 16px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .filter__input {
                        height: 45px;
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 16px;
                        border: 1px solid var(--gray-300);
                        cursor: pointer;
                        border-radius: ${isStateDropdownOpen ? '4px 4px 0 0 ' : '4px'};
                    }
                    .filter__options {
                        background: #fff;
                        position: absolute;
                        z-index: ${zIndex('dropdownMenu')};
                        min-width: 100%;
                        padding: 4px 0;
                        border: 1px solid var(--gray-300);
                        border-radius: 0 0 8px 8px;
                        cursor: pointer;
                        box-shadow: 0px 6px 5px rgba(000, 000, 000, 0.05);
                        text-align: left;
                    }
                    .filter__option {
                        margin-bottom: 4px;
                        padding: 4px 16px;
                    }
                    .filter__option:hover {
                        cursor: pointer;
                        background: var(--interactive-100);
                    }
                    .filter__clear {
                        color: var(--secondary-300);
                        font-size: 12px;
                        font-weight: normal;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                `}
            </style>
        </>
    );
};
export default Filter;

const states = [
    'California',
    'Florida',
    'Massachusetts',
    'Mississippi',
    'Nevada',
    'Rhode Island',
    'Texas',
    'Outpatient & Sober Living',
    'States Not Listed',
    'View All',
];

const insurances = [
    'Aetna',
    'Ambetter',
    'Anthem',
    'Blue Cross Blue Shield',
    'Cigna',
    'Kaiser Permanente',
    'Magellan',
    'Tufts',
    'UHC',
    'Insurance Not Listed',
    'View All',
];

const InsuranceFilterStyled = styled.div`
    &.filter:nth-of-type(2) {
        margin-top: 15px;

        ${({disableInsuranceFilter}) =>
            disableInsuranceFilter &&
            `
            opacity: 0.3;
            cursor: not-allowed;
        `}
    }
`;
