import {useRouter} from 'next/router';
import url from 'url';
import Link from 'next/link';
import CallrailLink from '../CallrailLink';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';
import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import {NextStepsItemTitleStyled} from './NextStepsStyles';
import AppContext from '../AppContext';
import {useContext} from 'react';
import {customMapLinks} from './utils';
import {treatmentCenterShortNames} from '../../lib/globals';
import IconShield from 'aac-components/components/Icons/Basic/IconShield';
import IconLocation from 'aac-components/components/Icons/Basic/IconLocation';
import IconHospital from 'aac-components/components/Icons/Building/IconHospital';
import IconClipboard from 'aac-components/components/Icons/Misc/IconClipboard';

const NextStepsNavDesktopV1 = () => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    const {
        sessionStorageState: {
            geolocation: {facility: geolocationSiteKey = '', location: city = ''} = {},
        } = {},
    } = useContext(AppContext);

    const facilityData = customMapLinks[geolocationSiteKey];

    let facilityShortName = treatmentCenterShortNames.find((shortName) => {
        return facilityData?.title.includes(shortName);
    });

    const items = [
        {
            href: facilityData?.url,
            Icon: () => <IconLocation fill="var(--primary-200)" width="24" height="24" />,
            title: `${facilityShortName} is an easy drive to us from ${city}`,
            styledDesktopCopy: `<span style="color: var(--interactive-300); text-decoration: underline;">${facilityShortName}</span> is an easy drive to us from ${city}`,
        },
        {
            href: '/verify-insurance',
            Icon: () => (
                <IconShield
                    fill="var(--primary-200)"
                    width="24"
                    height="24"
                    innerFill="var(--primary)"
                />
            ),
            desktopCopy: 'Check My Insurance',
        },
        ...(pathname === facilityData?.url
            ? [
                  {
                      href: '/treatment-centers',
                      Icon: (props) => (
                          <IconHospital
                              fill="var(--primary)"
                              width="20"
                              height="20"
                              {...props}
                          />
                      ),
                      desktopCopy: 'View Treatment Centers',
                  },
                  {
                      href: '/admissions',
                      Icon: (props) => (
                          <IconClipboard
                              fill="var(--primary)"
                              width="20"
                              height="20"
                              {...props}
                          />
                      ),
                      desktopCopy: 'See Admissions Process',
                  },
              ]
            : []),
    ];

    return (
        <div className="next-steps-nav__desktop">
            <div className="container">
                {Array.isArray(items) &&
                    items.map((item, index) => {
                        const {href, Icon, title, desktopCopy, styledDesktopCopy} = item;

                        if (href === pathname) return null;

                        return (
                            <div key={index}>
                                <Link
                                    href={href}
                                    title={title || desktopCopy}
                                    style={{
                                        color: 'orange',
                                        fontSize: '20px',
                                        lineHeight: '20px',
                                        display: 'flex',
                                        textDecoration: 'none',
                                    }}>
                                    <div className="next-steps__item">
                                        <div className="next-steps__item--icon">
                                            <Icon />
                                        </div>
                                        <NextStepsItemTitleStyled
                                            dangerouslySetInnerHTML={{
                                                __html: styledDesktopCopy || desktopCopy,
                                            }}
                                        />
                                        <div>
                                            <IconChevron
                                                rotate={'-90'}
                                                fill="var(--primary-200)"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                <div className="next-steps__item phone">
                    <div>
                        <IconPhone fill="var(--primary-200)" />{' '}
                    </div>
                    <div>
                        <CallrailLink style={{color: '#fff', textDecoration: 'none'}} />
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .next-steps-nav__desktop {
                        height: 75px;
                        background: var(--primary-300);
                        box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                    }
                    .container {
                        height: 75px;
                        max-width: ${MAX_WIDTH_PX};
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;
                    }

                    .next-steps__item {
                        color: #fff;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .next-steps__item > div:nth-child(1) {
                        margin-right: 8px;
                        display: flex;
                    }
                    .next-steps__item > div:last-child {
                        display: flex;
                        align-items: center;
                        margin-left: 8px;
                    }
                    .next-steps__item.phone > div:nth-child(1) {
                        position: relative;
                    }
                    .next-steps__item.phone > div:nth-child(1)::after {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: var(--success);
                        border-radius: 100%;
                        right: 0px;
                        position: absolute;
                    }
                    .next-steps__item--title {
                        text-decoration: none;
                    }
                `}
            </style>
        </div>
    );
};

export default NextStepsNavDesktopV1;
