import styled from 'styled-components';
import {MAX_WIDTH_PX} from 'aac-components/utils/styles';

export const NextStepsNavItemsStyled = styled.div`
    span {
        color: #fff !important;
    }
    ul {
        min-width: 100% !important;
        display: grid !important;
        grid-template-columns: repeat(4, auto) !important;
        justify-content: space-between !important;
        grid-gap: 80px !important;
    }
    a {
        color: #fff !important;
        text-decoration: none;
    }
    svg {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(125deg) brightness(103%)
            contrast(103%);
        opacity: 0.7;
    }
    li:last-child .tooltip__icon svg:last-child {
        filter: none !important;
        opacity: 1 !important;
    }
`;

export const NextStepsItemTitleStyled = styled.div`
    text-decoration: none;
    color: white;
`;

export const NextStepsDesktopContainerStyled = styled.div`
    height: 75px;
    background: var(--primary-300);
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
`;

export const NextStepsDesktopItemsStyled = styled.div`
    height: 75px;
    max-width: ${MAX_WIDTH_PX};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
`;

export const NextStepsDesktopItemStyled = styled.div`
    color: #fff;
    font-size: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    div:nth-child(1) {
        margin-right: 8px;
        display: flex;
    }
    div:last-child {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }
    div:nth-child(1) {
        position: relative;
    }
    div:nth-child(1)::after {
        content: '';
        width: 8px;
        height: 8px;
        background: var(--success);
        border-radius: 100%;
        right: 0px;
        position: absolute;
    }
`;

export const NextStepsDesktopPhoneStyled = styled.div`
    div:nth-child(1) {
        position: relative;
    }

    div:nth-child(1)::after {
        content: '';
        width: 8px;
        height: 8px;
        background: var(--success);
        border-radius: 100%;
        right: 0px;
        position: absolute;
    }
`;
