import HiddenOnDesktop from '../HiddenOnDesktop';
import HiddenOnMobile from '../HiddenOnMobile';
import NextStepsNavDesktopV1 from './NextStepsNavDesktopV1';
import NextStepsNavMobileV1 from './NextStepsNavMobileV1';

const Variation1 = ({isMobile}) => {
    return (
        <>
            {isMobile ? (
                <HiddenOnDesktop>
                    <NextStepsNavMobileV1 />
                </HiddenOnDesktop>
            ) : (
                <HiddenOnMobile>
                    <NextStepsNavDesktopV1 />
                </HiddenOnMobile>
            )}
        </>
    );
};

export default Variation1;
