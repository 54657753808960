import {useContext} from 'react';
import styled from 'styled-components';
import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import CallrailLink from '../CallrailLink';
import {getSegmentNumber} from '../../lib/utils';
import AppContext from '../AppContext';
import Banner from 'aac-components/components/Banner';

const MobileBanner = () => {
    const {pageProps: {data: {acf: {content_segment = ''} = {}} = {}} = {}} =
        useContext(AppContext);
    const segmentNumber = getSegmentNumber(content_segment);

    const showSmsAndPhone = segmentNumber <= 2;
    const onlyShowPhone = segmentNumber >= 3;

    const messages = [
        () => (
            <CallrailLinkStyles>
                Call{' '}
                <CallrailLink
                    style={{
                        fontWeight: 'bold',
                        fontSize: '12px',
                    }}
                />
            </CallrailLinkStyles>
        ),
        '<a href="/not-ready-to-talk">Get Text Support</a>',
    ];

    return (
        <div className="banner">
            {showSmsAndPhone && <Banner messages={messages} />}
            {onlyShowPhone && (
                <div className="banner__call">
                    <div>
                        <IconPhone fill="var(--interactive)" width="16" height="16" />
                    </div>
                    <div className="banner__call--link">
                        <CallrailLinkStyles>
                            <CallrailLink
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                }}
                            />
                        </CallrailLinkStyles>
                    </div>
                </div>
            )}
            <style jsx>
                {`
                    .banner {
                        height: 40px;
                        background: #fff;
                        display: grid;
                        grid-template-columns: '1fr';
                        align-items: center;
                        font-size: 12px;
                        font-weight: bold;
                    }
                    .banner > div {
                        height: 40px;
                        padding: 8px;
                        text-align: center;
                    }
                    .banner__call {
                        background: var(--interactive-100);
                        display: grid;
                        grid-template-columns: 16px auto;
                        justify-content: center;
                        align-items: center;
                        grid-gap: 4px;
                        box-shadow: ${showSmsAndPhone
                            ? 'inset -7px 0 9px -7px rgba(0, 0, 0, 0.15)'
                            : 'none'};
                    }

                    .banner__call > div:first-child {
                        position: relative;
                    }
                    .banner__call > div:first-child::after {
                        content: '';
                        width: 6px;
                        height: 6px;
                        background: var(--success);
                        border-radius: 100%;
                        right: 0px;
                        position: absolute;
                    }
                    .banner__call--link {
                    }
                    .banner__sms {
                        background: var(--tertiary-300);
                        color: var(--primary-300);
                        text-decoration: underline;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                `}
            </style>
        </div>
    );
};
export default MobileBanner;

const CallrailLinkStyles = styled.div`
    .tooltip__call {
        margin-top: -4px;
    }
`;
